import React, {useState} from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import MenuLink from './MenuLink'

export default function MainMenu() {

  const data = useStaticQuery(graphql`
    query MainMenuQuery {
      site {
        siteMetadata {
          company
        }
      }
    }
  `)

  const {company} = data.site.siteMetadata

  const [toggleMenu, setToggleMenu] = useState(false)
  const menuItems = [ 
    {title: "Granite", link: "/granite-countertops"},
    {title: "Quartz", link: "/quartz-countertops"},
    {title: "Marble", link: "/marble-countertops"},
    {title: "Contact", link: "/contact"},
  ]
  return (
    <div>
      <nav>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link to="/">
                <div className="flex-shrink-0">
                  <StaticImage 
                    src="../../../images/logo/logo.png" 
                    alt={`${company} Logo`} 
                    layout="fixed"
                    height={100}
                  />
                </div>
              </Link>
            </div>

            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  { menuItems.map((item) => (
                    <MenuLink key={item.title} title={item.title} link={item.link || "/"}/>
                  ))}
                  <Link to="/contact">
                    <div className="bg-primary-600 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Free Estimate
                    </div>
                  </Link>

                </div>
              </div>
            </div>
            
            <div className="-mr-2 flex md:hidden">
              <button 
                onClick={() => setToggleMenu(!toggleMenu)}
                className="bg-secondary-600 inline-flex items-center justify-center p-2 rounded-md text-primary-200 hover:text-white hover:bg-secondary-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-600 focus:ring-white"
                >
                <span className="sr-only">Open main menu</span>
                <svg className={`${toggleMenu ? "hidden" : "block"} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className={`${!toggleMenu ? "hidden" : "block"} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className={`${toggleMenu ? "block" : "hidden"}`}>
          <div className="mt-8 px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-primary-200">
            { menuItems.map((item) => (
                  <div key={item.title} className="block text-base py-2">
                    <MenuLink title={item.title} link={item.link || "/"}/>
                  </div>
            ))}
          </div>
          
        </div>
      </nav>
    </div>


  )
}
