import React from 'react'

import SEO from '../../components/seo'
import MainMenu from './mainMenu/MainMenu'
import Footer from './Footer'

export default function MainLayout({metaData, children}) {

  return (
    <div className="flex flex-col h-screen">
      <SEO metaData={metaData}/>

      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <MainMenu />
        </div>
      </header>

      <main className="flex-grow">
          <div className="px-4 py-0 sm:px-0 shadow-xl border-4">
            {/* <div className="border-4 border-dashed border-gray-200 rounded-lg h-96"> */}
              {children}
            {/* </div> */}
          </div>
      </main>

      <Footer />
    </div>
  )
}
