import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import parsePhoneNumber from 'libphonenumber-js/min'


export default function Footer() {

  const year = new Date().getFullYear()

  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          company
          mainContactPhone
          mainEmail
          mainAddress {
            city
            state
          }
        }
      }
    }
  `)

  const {company, mainAddress, mainContactPhone, mainEmail} = data.site.siteMetadata
  const phoneNumber = parsePhoneNumber(mainContactPhone).formatNational()

  return (


    <footer className="bg-gray-800" aria-labelledby="footerHeading">


      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 space-between">
        <div className="m-8">
          <StaticImage 
            src="../../images/logo/logo-dark-background.png" 
            alt={`${company} Logo`} 
            layout="fixed"
            height={128}
          />
        </div>

        <div className="mt-8">
          <h3 className="text-md font-semibold text-gray-400 tracking-wider uppercase">
            {company}
          </h3>
          <ul className="mt-4 space-y-4">
            <li className="text-base text-gray-300">
                {mainAddress.city}, {mainAddress.state}
            </li>

            <li>
              <a href="tel:8642074743" className="text-base text-gray-300 hover:text-white">
                {phoneNumber}
              </a>
            </li>

            <li>
              <a href="mailto:info@greenvillecountertops.com" className="text-base text-gray-300 hover:text-white">
                {mainEmail}
              </a>
            </li>

          </ul>
        </div>

        <div className="mt-8 invisible lg:visible">
          <h3 className="text-md font-semibold text-gray-400 tracking-wider uppercase">
            Materials
          </h3>
          <ul className="mt-4 space-y-4">
            <li>
              <Link to="/granite-countertops" className="text-base text-gray-300 hover:text-white">
                Granite
              </Link>
            </li>

            <li>
              <Link to="/quartz-countertops" className="text-base text-gray-300 hover:text-white">
                Quartz
              </Link>
            </li>

            <li>
              <Link to="/marble-countertops" className="text-base text-gray-300 hover:text-white">
                Marble
              </Link>
            </li>

          </ul>
        </div>

      </div>

      <div className="text-center lg:text-left lg:mr-4">
        <p className="text-sm text-gray-400">
          &copy; {year} {company} All rights reserved.
        </p>
      </div>
    </footer>


  )
}
