import React from 'react'
import {Link} from 'gatsby'

export default function MenuLink(props) {
  const {title, link} = props
  return (
    <Link 
      to={link} 
      className="font-medium text-gray-500 hover:bg-primary-100 hover:bg-opacity-75 px-3 py-2 rounded-md font-large"
      >{title}
    </Link>
  )
}
